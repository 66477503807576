import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Link from '../components/Link';
import Button from '../components/UI/Button';
import '@scss/_contact.scss';

function Contact() {
  const title = 'İletişim - Tıkla Gelsin';
  const metaData = 'Tıkla Gelsin iletişim bilgilerine bu sayfadan ulaşabilirsiniz.';
  const rows = [
    { id: 1, label: 'Şirket Türü', value: 'A.Ş.' },
    { id: 2, label: 'Mersis', value: '0092045239100016' },
    { id: 3, label: 'Ticaret Sicil Memurluğu', value: 'İSTANBUL' },
    { id: 4, label: 'Ticaret Sicil Numarası', value: '62442-5' },
    {
      id: 5,
      label: 'Ticaret Unvanı',
      value: 'ATA EXPRESS ELEKTRONİK İLETİŞİM TANITIM PAZARLAMA DAĞITIM SANAYİ VE TİCARET A.Ş.',
    },
    {
      id: 6,
      label: 'Adres',
      value: 'AYAZAĞA MAH. KEMERBURGAZ CAD. VADİ İSTANBUL PARK 7A BLOK NO: 7 B İÇ KAPI NO: 26 SARIYER, İSTANBUL',
    },
    { id: 7, label: 'Kep Adresleri', value: 'ataexpress@hs02.kep.tr' },
    { id: 8, label: 'Şirket Tescil Tarihi', value: '23-12-2016' },
    {
      id: 9,
      label: 'Vergi Dairesi',
      value: 'İSTANBUL - Maslak Vergi Dairesi Müdürlüğü',
    },
    { id: 10, label: 'Vergi Numarası', value: '0920452391' },
    {
      id: 11,
      label: 'Sektör',
      value: 'Radyo, TV, posta yoluyla veya internet üzerinden yapılan perakende ticaret',
    },
    { id: 12, label: 'Telefon', value: '08508118484' },
    {
      id: 13,
      label: 'İletişim Bilgileri',
      value: 'İnternet Adresi - www.tiklagelsin.com.tr - www.fiyuu.com.tr',
    },
  ];

  return (
    <Layout sidebar={false} hasBreadCrumb>
      <SEO title={title} description={metaData} />
      <div className="contactContainer">
        <h2>İletişim Bilgileri</h2>
        <table className="info-table">
          <tbody>
            {rows?.map((row) => (
              <tr key={row?.id}>
                <td>{row?.label}</td>
                <td>{row?.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Link to="/" className="redirect-button-container">
        <Button className="redirect-button">Ana Sayfaya Dön</Button>
      </Link>
    </Layout>
  );
}

Contact.propTypes = {};

export default Contact;
